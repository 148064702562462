
import {computed, defineComponent} from 'vue'
import Row from "@/components/base/common/Row.vue";
import store from "@/store";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "CustomerOverview",
  components: {Row},
  setup() {
    const entity = computed(() => store.state.CustomerModule.entity);
    return {
      entity,
      ...LoadPanel()
    }
  }
})
